
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'link-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('linkList'), [
          translate('assets'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_LINKS_STORE);
      });

      const editIcon = (id) => {
        router.push({ name: 'link-editing', params: { id } });
      };

      await store.dispatch(Actions.GET_LINKS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('id'),
          key: 'id',
        },

        {
          name: translate('titleEn'),
          key: 'titleEn',
        },
        {
          name: translate('titleAr'),
          key: 'titleAr',
        },
        {
          name: translate('externalLink'),
          key: 'externalLink',
        },
        {
          name: translate('sort'),
          key: 'sort',
        },
        // {
        //   name: translate('active'),
        //   key: 'isActive',
        // },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatuslink = async (link) => {
        const deletedMessage = link.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_LINK, link.id);
          await store.dispatch(Actions.GET_LINKS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = link.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };
      const editlink = (id) => {
        router.push({ name: 'link-editing', params: { id } });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_LINKS_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_LINKS);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.linksList),
        totalItems: computed(() => store.getters.linksListCount),
        tableHeader,
        toggleStatuslink,
        translate,
        pageChanged,
        goTo,
        editlink,
        editIcon,
        loading,
        can,
      };
    },
  });
